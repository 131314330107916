import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThanksPage = props => (
  <Layout>
    <SEO title="Thanks" />
    <h1>Thanks</h1>

    <p>
      Thanks for getting in touch. We will get back to you shortly. 
    </p>
  </Layout>
)

export default ThanksPage
